<template>
  <component :is="selectedIcon" />
</template>

<script>
import Ceiling from '@/assets/svg/categories/ceiling.svg?inline'
import Chimney from '@/assets/svg/categories/chimney.svg?inline'
import ExteriorTiles from '@/assets/svg/categories/exterior-tiles.svg?inline'
import Facade from '@/assets/svg/categories/facade.svg?inline'
import Floor from '@/assets/svg/categories/floor.svg?inline'
import Gutters from '@/assets/svg/categories/gutters.svg?inline'
import Installations from '@/assets/svg/categories/installations.svg?inline'
import Insulation from '@/assets/svg/categories/insulation.svg?inline'
import InteriorTiles from '@/assets/svg/categories/interior-tiles.svg?inline'
import Plinth from '@/assets/svg/categories/plinth.svg?inline'
import Roof from '@/assets/svg/categories/roof.svg?inline'
import SoftJoints from '@/assets/svg/categories/soft-joints.svg?inline'
import Walls from '@/assets/svg/categories/walls.svg?inline'
import Window from '@/assets/svg/categories/window.svg?inline'
import Woodwork from '@/assets/svg/categories/woodwork.svg?inline'
import SoleBenches from '@/assets/svg/categories/sole-benches.svg?inline'
import Stairs from '@/assets/svg/categories/stairs.svg?inline'
import Balcony from '@/assets/svg/categories/balcony.svg?inline'

export default {
  name: 'CategoryIcon',
  props: {
    categoryID: {
      type: String,
      required: true,
    },
  },
  computed: {
    selectedIcon() {
      if (this.categoryID === 'C-1') {
        return Roof
      }
      if (this.categoryID === 'C-2') {
        return Facade
      }
      if (this.categoryID === 'C-3') {
        return Plinth
      }
      if (this.categoryID === 'C-4') {
        return Window
      }
      if (this.categoryID === 'C-5') {
        return Woodwork
      }
      if (this.categoryID === 'C-6') {
        return ExteriorTiles
      }
      if (this.categoryID === 'C-7') {
        return InteriorTiles
      }
      if (this.categoryID === 'C-8') {
        return Walls
      }
      if (this.categoryID === 'C-9') {
        return Floor
      }
      if (this.categoryID === 'C-10') {
        return Ceiling
      }
      if (this.categoryID === 'C-11') {
        return Woodwork
      }
      if (this.categoryID === 'C-12') {
        return Installations
      }
      if (this.categoryID === 'C-13') {
        return Insulation
      }
      if (this.categoryID === 'C-14') {
        return Gutters
      }
      if (this.categoryID === 'C-15') {
        return SoftJoints
      }
      if (this.categoryID === 'C-16') {
        return Chimney
      }
      if (this.categoryID === 'C-17') {
        return SoleBenches
      }
      if (this.categoryID === 'C-18') {
        return Stairs
      }
      if (this.categoryID === 'C-19') {
        return Balcony
      }
      return ''
    },
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryIcon
    display block
</style>
